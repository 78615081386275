exports.list = {
    path: '/approval_order',
    name: 'approval_order', 
      meta: {
        authRequired: true,
      },
    component: () => import("@/views/main/approval_order/List.vue"),
}
exports.show = {
    path: '/approval_order/show/:no_transaksi',
    name: 'approval_order_show', 
        meta: {
            authRequired: true,
        },
    component: () => import("@/views/main/approval_order/Show.vue"),
}